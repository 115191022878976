import * as React from 'react'
import { styled, globalCss } from '@stitches/react'
import { reset } from 'stitches-reset'

import Seo from '../components/seo'

const globalStyles = globalCss(reset)

const Page = styled('div', {
  width: '100vw',
  height: '100vh',
  background: 'rgb(102,63,221)',
  background: 'linear-gradient(90deg, rgba(102,63,221,1) 0%, rgba(59,26,159,1) 100%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
})

const NotFoundPage = () => {
  globalStyles()
  return (
    <Page>
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Page>
  )
}

export default NotFoundPage
